.hoox-reviews {
    padding: 48px 0;
}
.hoox-reviews__title {
    font-size: 27px;
    color: #414D57;
    margin-bottom: 32px;
    text-align: center;
}
.hoox-reviews__item {
    padding: 20px;
    box-sizing: border-box;
    height: 393px;
    display: flex;
    flex-direction: column;
}
.hoox-review__stars {
    display: flex;
}

.hoox-review__title {
    font-size: 20px;
    line-height: 24px;
    margin: 16px 0 14px;
}
.hoox-review__description {
    font-size: 16px;
    line-height: 24px;
    color: #414D57;
}
.hoox-review__author {
    font-size: 18px;
    font-style: italic;
    margin-top: auto;
}
.hoox-review__verified {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 3px;
    color: #414D57;
    font-size: 14px;
}

/*Pagination*/

.hoox-reviews .swiper-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
}

.hoox-reviews__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
    height: 24px;
    background: var(--hoox-color-white);
    position: static;
    border-radius: 100px;
    gap: 7px;
    padding: 0 10px;
}

.hoox-reviews .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--hoox-color-slate-secondary);
}

.hoox-reviews .swiper-pagination-bullet {
    border: 1px solid var(--hoox-color-slate-secondary);
    box-sizing: border-box;
    background: transparent;
    opacity: 1;
}

.hoox-reviews .hoox-review-arrows {
    width: 32px;
    height: 32px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.hoox-reviews .hoox-review-arrows svg {
    transform: scale(.5);
}

.hoox-review__image {
    max-width: 16px;
    max-height: 16px;
}

.hoox-review__verified-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
}

/*Desktop*/

@media(min-width:768px){

    .hoox-review-arrows {
        cursor: pointer;
    }

    .hoox-reviews{
        padding: 80px 0;
        max-height: 694px;
    }
    .hoox-reviews__item {
        padding: 24px;
        height: 381px;
        max-width: 378px;
    }

    .hoox-reviews__title{
        font-size: 40px;
        max-width: 490px;
        margin: 0 auto 48px;
    }

    .hoox-review__title {
        
        margin: 14px 0 14px;
    }

    .hoox-reviews .swiper-wrapper {
        display: flex;
        justify-content: space-between;
        margin-inline: auto;
    }

}

/*Tablet/Portrait view*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
    
.hoox-reviews {
    max-height: unset;
}

.hoox-reviews__item {
    height: 490px;
}

.hoox-review__description { 
    font-size: 14px;
    line-height: 22px;
}

}

.hoox-reviews-lp-1 {
    background: var(--hoox-color-lilac);
}

.hoox-reviews-lp-1 .hoox-reviews__item{
    background: var(--hoox-color-white);
}

.hoox-reviews-lp-2 {
    background: var(--hoox-color-yellow);
}

.hoox-reviews-lp-2 .hoox-reviews__item{
    background: var(--hoox-color-white);
}

.hoox-reviews-lp-3 {
    background: var(--hoox-color-white);
}

.hoox-reviews-lp-3 .hoox-reviews__item{
    background: var(--hoox-color-yellow);
}

.hoox-reviews-lp-3 .hoox-reviews__item, .hoox-reviews-lp-2 .hoox-reviews__item{
    height: 331px;
    padding: 22.5px 20px;
}
@media (max-width: 768px) {
    .hoox-reviews-lp-3 .hoox-reviews__title, .hoox-reviews-lp-2 .hoox-reviews__title {
        font-size: 28px;
        line-height: 39px;
        margin-bottom: 39px;
        max-width: 300px;
        margin-inline: auto;
    }

    .hoox-reviews-lp-2 .swiper-navigation,  .hoox-reviews-lp-3 .swiper-navigation {
        margin-top: 41px;
    }

    .hoox-reviews-lp-3 .swiper-navigation {
        gap: 16px;
    }

    .hoox-reviews-lp-3 .hoox-review__title, .hoox-reviews-lp-2 .hoox-review__title {
        margin: 16px 0 17px;
    }
    .hoox-reviews-lp-3 .hoox-reviews__item{
        padding: 22.5px 22px;
    }
   
}

.hoox-reviews-lp-1 .hoox-review-arrows, .hoox-reviews-lp-2 .hoox-review-arrows {
    background: var(--hoox-color-white);
}

.hoox-reviews-lp-1 .hoox-reviews__dots, .hoox-reviews-lp-2 .hoox-reviews__dots {
    background: var(--hoox-color-white);
}

.hoox-reviews-lp-3 .hoox-review-arrows {
    background: var(--hoox-color-grey);
}

.hoox-reviews-lp-3 .hoox-reviews__dots {
    background: var(--hoox-color-grey);
    gap: 1px;
}

.hoox-reviews-lp-2 {
    padding: 41px 0 48px;
}


@media (min-width: 767px) {
    .hoox-reviews-lp-3 .hoox-reviews__title, .hoox-reviews-lp-2 .hoox-reviews__title {
        max-width: unset;
        margin-bottom: 40px;
    }
    .hoox-reviews-lp-2, .hoox-reviews-lp-3 {
        padding: 72px 0;
    }

    .hoox-reviews-lp-2 .hoox-reviews__item, .hoox-reviews-lp-3 .hoox-reviews__item {
        max-width: 368px;
    }

  
}

@media (max-width: 1023px) and (min-width: 768px) {
    .hoox-reviews .hoox-reviews__item {
        max-width: unset; 
    }
}
